<script>
import Layout from "../../layouts/main";
import EasyDataTable from "vue3-easy-data-table";
import 'vue3-easy-data-table/dist/style.css';

export default {

  components: {
    Layout,
    EasyDataTable
  },
  data() {
    return {
        searchValueTable:'',
        selectFilterSort :null,
        headers:[
            { text: "S.No", value: "serialNumber" },
            { text: "Application Number", value: "appNumber" },
            { text: "Status", value: "status" },
            { text: "View Application", value: "viewApp" }
        ],
        items: [
            { "serialNumber": 1, "appNumber": '#179948', "status": 'In progress', "viewApp": '', },
            { "serialNumber": 2, "appNumber": '#183440', "status": "Submitted and Under Review", "viewApp": '', },
            { "serialNumber": 3, "appNumber": '#185671', "status": "Incomplete", "viewApp": '', },
            { "serialNumber": 4, "appNumber": '#181341', "status": "Approved", "viewApp": '', },
            { "serialNumber": 4, "appNumber": '#112481', "status": "Rejected", "viewApp": '', }
        ],
        arrItem:[]
    }
  },
  created()
  {
    this.arrItem = this.items
  },
  methods: {
    filterStatus(){
      if(this.selectFilterSort==null)
      {
        this.arrItem = this.items
      }
      else{
        this.arrItem = this.items.filter((item)=>{
       return  item.status==this.selectFilterSort
      })
      }
      
    }
  }

};
</script>

<template>
  <Layout>
    <main class="py-4">
      <div class="row mb-3">
        <div class="col">
          <h2 class="font-size-22 text-theme-dark">Old Records</h2>
        </div>
      </div>
      <div class="card">
          <div class="card-body">
              <div class="row align-items-center mb-3">
                  <div class="col-md-5">
                    <label class="font-size-14 mb-1 me-2 ">Filter Status</label>
                    <select
                      class="form-select form-control selectCustomSize"
                      v-model="selectFilterSort"
                      name="favouriteSport"
                      @change="filterStatus">
                      <option selected :value="null">
                        All
                      </option>
                      <option value="In progress">
                        In progress
                      </option>
                      <option value="Incomplete">
                        Incomplete
                      </option>
                      <option value="Approved">
                        Approved
                      </option>
                      <option value="Rejected">
                        Rejected
                      </option>
                    </select>
                  </div>
                  <div class="col mt-3 mt-md-0">
                      <div class="groupSearchTop">
                          <label class="font-size-14 mb-1 me-2 ">Search</label>
                          <input type="text" v-model="searchValueTable" class="customInputForm form-control">
                      </div>
                  </div>
                  <div class="col-md-auto mt-3 mt-md-0">
                      <router-link to="/membership_application" class="btn btn-theme">Add Member Application</router-link>
                  </div>
              </div>
              <EasyDataTable
                  :headers="headers"
                  :items="arrItem"
                  :search-value="searchValueTable"
                  border-cell
                  theme-color="#df6a0d"
                  :rows-per-page="10"
                  buttons-pagination
                  table-class-name="table-custom-style"
              >
              <template #item-viewApp="">
                <router-link to="/membership_application" class="btn btn-theme">
                <i class="mdi mdi-eye"></i> View
                </router-link>
              </template>
            </EasyDataTable>
          </div>
      </div>
    </main>
  </Layout>
</template>
